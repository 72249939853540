.container {
  position: fixed;
  width: 0%;
  background-color: white;
  height: 100%;
  z-index: 99999;
  top: 0;
  box-shadow: -6px 0 16px -8px #00000014, -9px 0 28px #0000000d,
    -12px 0 48px 16px #00000008;
}

@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header_title {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.title {
  flex: 1;
  margin: 0;
  color: #000000d9;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.close_btn {
  display: inline-block;
  /* margin-right: 12px; */
  color: #00000073;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: 0 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.overlay {
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  /* background: black; 
     opacity: 0.5 ;  
    display: none;*/
}

.active {
  /* width: 350px !important; */
  display: block !important;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  /* transform: translate(100%); */
}

.header {
  /*
  font-size: 16px;
  font-weight: bold;
  opacity: 0.8;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06); */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 16px 24px;  */
  padding: 16px;
  color: rgba(0, 0, 0, 0.85);
  background: white;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.content {
  padding: 16px;
}

@media only screen and (max-width: 400px) {
  .container {
    width: 100% !important;
  }
}
/* transition: width 0.2s ease-in;  */
/* transition: all 0.3s;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
  box-shadow: 6px 0 16px -8px #00000014, 9px 0 28px #0000000d,
    12px 0 48px 16px #00000008;
  transform: translate(0); */
/* transition: width 0s ease 0.3s, height 0s ease 0.3s; */
/* transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1),
  box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1);  */
