.container {
  padding: 10px 30px;
  background: #f1f5f8;
}
.container h5 {
  font-size: 12.5px;
}
/* .circle {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6366F1;
  color: white;
  z-index: 999;
} */

/* .indicator {
  height: 60;
  width: 3;
  background: green;
  border-radius: 10;
  margin: auto;
  display: table;
} */
